@charset "utf-8";

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Light.ttf") format("truetype");
}

// Our variables
$base-font-family: "Roboto", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$medium-font-size:  $base-font-size * 1.125;
$big-font-size:  $base-font-size * 4;
$base-line-height: 1.5;

// $spacing-unit:     30px;

$text-color:       #fff;
$text-color-dark:  #2b2b2b;
$background-color: #2b2b2b;
$background-color-light: #d5d5d5;
$menu-color:       #083d4d;
$brand-color:      #53bbb2;

// $grey-color:       #828282;
// $grey-color-light: lighten($grey-color, 40%);
// $grey-color-dark:  darken($grey-color, 25%);

// // Width of the content area
// $content-width:    800px;

// $on-palm:          600px;
// $on-laptop:        800px;


$hidden-menu-border: 3px solid $menu-color;
$button-border: 3px solid $brand-color;

body, html {
	font-family: $base-font-family;
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    color: $text-color;
    background-color: $background-color;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;
	font-smooth: always;

	font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-webkit-font-smoothing: subpixel-antialiased;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
// a{
// 	text-decoration: underline;
// }
input{
	color: $background-color;
   font-size: $medium-font-size; 
    border: $button-border;
}
h1{
	font-size: $big-font-size;
}
h2{
    // font-size: $font-size;
}
hr{
	width: 10%;
}
.medium{
	font-size: $medium-font-size;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	// padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0%;
	width: 100%;
	height: 100%;
}


.platform-icon {
    margin-top: 5px;
    width: 150px;
    padding: 0px;
}

// MENU
.navbar-nav{
    float: none;
    text-align: center;
}

@media(min-width:767px) {
    .navbar-nav{
        float:right;
    }
}

.navbar {
    padding: 5px 0;
}
.navbar {
    background-color: $menu-color;
    // color: $text-color;
}
.navbar-default
{
	border: none;
}
.navbar-default .navbar-collapse
{
	border: none;
}
.navbar-default .navbar-nav>li>a{
	// font-size: 1.2em;
    color: $text-color;
    border: $hidden-menu-border;
}
.navbar-default .navbar-nav>li>a:hover,{
 	color: $text-color;
    border: $button-border;
    background: transparent;
}
.navbar-default .navbar-nav>li>a:focus{
	color: $text-color;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover{
 	color: $text-color;
    border: $button-border;
    background-color: $menu-color;
}

.single-logo {
    margin-top: 20px;
}

.partner-logo{
    margin: 10px;
    max-height: 120px;
    max-width: 200px;
    opacity: 0.7;
    filter: alpha(opacity=50); /* For IE8 and earlier */
}

.partner-logo:hover{
    opacity: 1;
}

.logo {
    height: 50px;
    margin-top: -13px;
}

/*.contact-section .logo {
    width: 75px;
    margin: auto;
    text-align: center;
}
.contact-section .logo img {
    width: 100%;
}*/
// MENU
.button, 
.button:focus, 
.button:hover,
.button:target,
.button:active,
{
	color: $text-color;
	border: $button-border;
	font-size: $medium-font-size;
	background: transparent;
	border-radius: 0px;
    margin-bottom: 3px;
}

.button:hover{
	// color: $brand-color;
	background: $brand-color;
}

// SECTIONS

.section{
    /*// min-height: 500px;*/
    /*// max-width: 980px;*/
    padding-bottom: 50px;
    padding-top: 0px;
    /*// border-top: 1px solid;*/
}

.intro-section {
	padding-top: 150px;
    text-align: center;
    background-repeat: no-repeat;
    // background-size: cover;
    // background-size: c
    min-height: 600px;
    
    background-position: center top;
    background-attachment: fixed;
    background-image: url('../img/corp/back.png');
}

.art-section {
    padding-top: 150px;
    // text-align: center;
    background-repeat: no-repeat;
    // background-size: cover;
    // background-size: c
    min-height: 600px;
    
    background-position: center top;
    // background-attachment: fixed;
    // background-image: url('../img/corp/back.jpg');
}

.section-title{
    padding-bottom: 30px;
    padding-top: 30px;
	text-align: center;
    color: #999;
    /*// font-size: $medium-font-size;*/
}

.no-padding{
	padding: 0px;
}

.light {
    background-color:$background-color-light;
    // color: $text-color-dark;
}

.contact-section {
    /*padding-top: 30px;*/
    padding-bottom: 30px;
    border-top: 1px solid #333;
    
    // background-color: #333;
}

.contact-section, .footer-section{
    font-size: $small-font-size;
    color: #999;
    background-color: #111;
}
.post-section{
    color: $text-color-dark;
    background-color: #fff;
}
.square{
    height: 530px;
	padding: 5%;
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.square a{
    color: $text-color; 
    text-decoration: underline;
}
a.button{
    text-decoration: none;
}
.story p{
    font-size: $base-font-size*0.9;
}
.story{
	background-color: #957b5f; 
}
.platforms
{
    background-color: #222; 
}
.mission{
	background-image: url("../img/corp/mission.jpg");
}
.lf-desc{
    background-color: #c7615e; 
}
.lfedu-desc{
    background-color: #3a7d4f; 
}
.craftomation-desc{
    background-color: #9c6e4a; 
}
.wtl-desc{
    background-color: #221f29; 
}
.rewire-desc{
    background-color: #5d7b8c; 
}
.ta-desc{
    background-color: #587f6d; 
}
.inmind2-desc{
	background-color: #1d5163; 
}
.incell-desc{
	background-color: #58497f; 
}
.inmind-desc{
	background-color: #292952; 
}
.vrobot-desc{
    background-color: #894b32; 
}

.arrived-desc{
    background-color: #40613e; 
}

.aidraw-desc{
    background-color: #1f444d; 
}
.rewire-desc{
    background-color: #499344; 
}
.jobs-section{
    background-color: #0f857c;
}
.lf{
    background: url("../img/corp/lf.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.lfedu{
    background: url("../img/corp/lfedu.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.craftomation{
    background: url("../img/corp/craftomation.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.wtl{
    background: url("../img/corp/wtl.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.rewire{
    background: url("../img/corp/rewire.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.ta{
    background: url("../img/corp/ta.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.rewire{
    background: url("../img/corp/rewire.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.inmind2{
	background: url("../img/corp/inmind2.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.aidraw{
    background: url("../img/corp/aidraw.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.vrobot{
    background: url("../img/corp/vrobot.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}

.arrived{
    background: url("../img/corp/arrived.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}

.inmind{
	background: url("../img/corp/inmind.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.incell{
	background: url("../img/corp/incell.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}
.contacts{
	text-align: right;
}

@media(max-width:530px) {
    .contact-section .col-xs-6{
        width: 100%;
    }
    .contacts{
        padding-top: 20px;
        text-align: left;
    }
}

// SECTIONS
.footer{
	font-size: $small-font-size;
	text-align: center;
}

// GALLERY
.gallery-section{
    text-align: center;
}
/* Style the Image Used to Trigger the Modal */
.image-preview {
    cursor: pointer;
    // transition: 0.3s;
    max-width: 256px;
    padding-bottom: 10px;
}

.image-preview:hover {opacity: 0.7;}
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
    margin: auto;
    display: block;
    border-radius: 0px;
    max-width: 80%;
    max-height: 80%;
    // max-width: 1000px;
}

/* Add Animation - Zoom in the Modal */
.modal-content { 
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)} 
    to {-webkit-transform:scale(1)}
}

@keyframes zoom {
    from {transform:scale(0)} 
    to {transform:scale(1)}
}

/* The Close Button */
.close {
    position: absolute;
    top: 100px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
    .modal-content {
        width: 100%;
    }
    #intro h1{
        font-size: $big-font-size*0.5;
    }
    #intro h2{
        font-size: $medium-font-size;
    }
}
// GALLERY